import { useDebouncedEffect, useDeepDependencies, useIsMobile } from '@common'
import { Button, TextInput } from '@components'
import { isEqual, pickBy } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'
import {
  DelimitedArrayParam,
  JsonParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialFilters } from '../../../common/constants'
import { SearchFilters } from '../../../common/types'
import { getPresetKey, randomString } from '../../../common/utils'
import {
  deletePreset,
  setPresets,
  setSelectedPreset,
  setShowFilters,
} from '../../../redux/userSlice'
import { FilterFields } from './FilterFields'
import { PresetButton } from './PresetButton'

// StringParam doesn't handle undefined values properly
const CustomStringParam: typeof StringParam = {
  ...StringParam,
  decode: val => (val === undefined ? null : StringParam.decode(val)),
  encode: val => (val === null ? '' : StringParam.encode(val)),
}

export const Filters = ({
  filters,
  filtersList,
  setFilters,
  requestData = () => {},
}: {
  filters: SearchFilters
  filtersList: readonly (keyof SearchFilters)[]
  setFilters: (value: SearchFilters) => void
  requestData?: () => void
}) => {
  const isMobile = useIsMobile()

  const dispatch = useAppThunkDispatch()

  const showFilters = useAppSelector(state => state.user.showFilters)

  const configuredInitialFilters = useMemo(
    () => pickBy(initialFilters, (_, key) => filtersList.includes(key as keyof SearchFilters)),
    [filtersList],
  )
  const filtersQueryParamConfig = useMemo(
    () =>
      filtersList.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: ['originCityAutocomplete', 'destinationCityAutocomplete'].includes(curr)
            ? withDefault(JsonParam, initialFilters[curr])
            : Array.isArray(initialFilters[curr])
              ? // @ts-ignore
                withDefault(DelimitedArrayParam, initialFilters[curr])
              : withDefault(CustomStringParam, initialFilters[curr]),
        }),
        {},
      ),
    [filtersList],
  )
  const [queryFilters, setQueryFilters] = useQueryParams(filtersQueryParamConfig)
  const [localFilters, setLocalFilters] = useState<SearchFilters>(configuredInitialFilters)
  const [localPresetTitle, setLocalPresetTitle] = useState('')
  const presets = useAppSelector(state => state.user.presets)
  const currentPreset = useMemo(() => {
    const currentLocationPreset = presets?.[getPresetKey()] || {}
    const id = currentLocationPreset?.selectedPreset || ''
    const preset = (currentLocationPreset?.presets || {})[id]
    return {
      id: currentLocationPreset?.selectedPreset || '',
      title: preset?.title || '',
      filters: preset?.filters || {},
    }
  }, [presets])

  useEffect(() => {
    if (isMobile) dispatch(setShowFilters(false))
  }, [isMobile])

  useEffect(() => {
    setLocalPresetTitle(currentPreset.title)
  }, [currentPreset.id])

  useDebouncedEffect(
    () => {
      const pickedFilters = pickBy(filters, (_, key) =>
        filtersList.includes(key as keyof SearchFilters),
      )

      if (!isEqual(queryFilters, localFilters) && !isEqual(queryFilters, pickedFilters)) {
        // the query params have changed, and the query params do not match
        // the local copy of the filters, or the filters that have been passed in from the parent
        setFilters(queryFilters)
        setLocalFilters(queryFilters)
      } else {
        // the parent has changed the filters, and the filters do not match
        // the local copy, or the initial values
        setLocalFilters(pickedFilters)
        setQueryFilters(pickedFilters)
      }
    },
    useDeepDependencies([currentPreset.id, filters, queryFilters, filtersList]),
  )

  const hasChanges = useMemo(
    () => !isEqual(currentPreset.filters, localFilters),
    [currentPreset.filters, localFilters],
  )

  const handleSaveChanges = async () => {
    if (hasChanges)
      await dispatch(
        setPresets({
          id: currentPreset.id,
          title: localPresetTitle,
          filters: localFilters,
          actionType: 'updated',
        }),
      )
  }

  const handleDeletePreset = async () => {
    await dispatch(deletePreset(currentPreset.id))
    setFilters(configuredInitialFilters)
    setQueryFilters(configuredInitialFilters)
  }

  const handleCancel = () => {
    dispatch(setSelectedPreset(''))
    dispatch(setShowFilters(!showFilters))
  }

  const handleApplyFilters = () => {
    setFilters(localFilters)
    setQueryFilters(localFilters)
    requestData()
  }

  const handleSaveAsNewPreset = async (title: string) =>
    await dispatch(
      setPresets({ id: randomString(), title, filters: localFilters, actionType: 'created' }),
    )

  return (
    <Container $showFilters={showFilters}>
      <div className='px-6 pb-0'>
        {currentPreset.id && (
          <TextInput
            sm
            className='mt-6'
            label='Preset Name'
            value={localPresetTitle}
            onChange={(title: string) => setLocalPresetTitle(title)}
          />
        )}
        <Header>
          <div>Filters</div>
          <ClearButton onClick={() => setLocalFilters(configuredInitialFilters)}>
            Clear All
          </ClearButton>
        </Header>
        <FiltersWrap>
          <FilterFields
            filtersList={filtersList}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
          />
        </FiltersWrap>
        <Footer>
          <Button
            mobileFullWidth
            className='mr-2 h-fit w-1/2'
            type='secondary'
            onClick={handleCancel}
          >
            Hide
          </Button>
          <PresetButton
            currentPresetTitle={currentPreset.title}
            isSaveChangesButtonDisabled={!hasChanges}
            onApply={handleApplyFilters}
            onDelete={handleDeletePreset}
            onSaveAsNewPreset={handleSaveAsNewPreset}
            onSaveChanges={handleSaveChanges}
          />
        </Footer>
      </div>
    </Container>
  )
}

const Container = tw.div`
  transition-all
  overflow-auto
  z-[2]
  lg:shadow-lg
  border-light-gray
  lg:rounded-tr-lg
  h-[calc(100vh-201px)]
  absolute
  top-0
  bottom-0
  bg-white
  lg:relative
  lg:max-h-full 
  lg:h-[calc(100%-91px)]
  ${({ $showFilters }: { $showFilters: boolean }) =>
    $showFilters
      ? 'w-full lg:w-[270px] max-w-full lg:max-w-[270px] lg:border-l lg:border-b lg:border-l-0 lg:border-b-0 lg:border-t lg:border-r mb-8 lg:mb-0'
      : 'w-0 max-w-0'}
`

const FiltersWrap = tw.div`
  grid
  grid-cols-1
  gap-y-3
  mb-px
`

const Header = tw.div`
  flex
  items-center
  justify-between
  font-semibold
  sticky
  top-0
  z-[8]
  py-6
  pr-6
  min-w-[244px]
  bg-white
`

const ClearButton = tw.div`
  text-link
  hover:text-error
  cursor-pointer
  transition-all
`

const Footer = tw.div`
  flex
  w-full
  justify-between
  sticky
  bottom-0
  bg-white
  pt-6
  h-[80px]
`
