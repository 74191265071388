import dayjs, { Dayjs } from 'dayjs'
import { camelCase, groupBy, isArray, isObject, mapKeys, mapValues, snakeCase } from 'lodash-es'
import moment from 'moment'

import { Document, TableOrder } from '../types'

export const keysToCamelCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map(keysToCamelCase)
  } else if (isObject(obj)) {
    return mapKeys(mapValues(obj, keysToCamelCase), (_, key) => camelCase(key))
  }

  return obj
}

export const capitalizeEachLetter = (string: string) => {
  const words = string.split(' ')
  return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ')
}

export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)

export const randomId = (): string => `_${Math.random().toString(36).slice(2, 11)}`

export const usPhone = (phone: string) =>
  phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

export const timeMask = [
  /[0-2]/,
  /[0-9]/,
  ':',
  /[0-5]/,
  /[0-9]/,
  ' ',
  '—',
  ' ',
  /[0-2]/,
  /[0-9]/,
  ':',
  /[0-5]/,
  /[0-9]/,
]

export const toCamel = (s: any) =>
  s.replace(/([-_][a-z])/gi, ($1: string) => $1.toUpperCase().replace('-', '').replace('_', ''))

// export const isArray = function (a: any[]) {
//   return Array.isArray(a)
// }
//
// export const isObject = function (o: any[]) {
//   return o === Object(o) && !isArray(o) && typeof o !== 'function'
// }

// @ts-ignore
export const keysToCamel = (o: any) => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: any) => {
      // @ts-ignore
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    // @ts-ignore
    return o.map(i => keysToCamel(i))
  }

  return o
}

export const keysToSnakeCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map(keysToSnakeCase)
  } else if (isObject(obj)) {
    return mapKeys(mapValues(obj, keysToSnakeCase), (_, key) => snakeCase(key))
  }

  return obj
}

// Returns the fully formatted document title for a given page.
export const getDocumentTitle = (pageName: string) => `${pageName} | Pulse by EXO Freight`

export const formatUSDate = (date?: string) => (date ? moment(date).format('MM/DD/YYYY') : '')

export const displayDateTime = (date?: any) =>
  date ? dayjs(date).format('MM/DD/YYYY hh:mm A') : '—'

export const displayDate = (date?: string | Date) => (date ? dayjs(date).format('MM/DD/YYYY') : '—')

export const displayDateWithWeekDay = (date?: string) =>
  date ? dayjs(date).format('dddd MM/DD/YY') : '—'

export const formatDateForBackend = (date?: Date | Dayjs | null | string) =>
  date ? dayjs(date).format('YYYY-MM-DD') : null

export const setCityCase = (city: string) => city.toLowerCase().split(' ').map(capitalize).join(' ')

export const displayCityAndState = (city: any, state: any) => {
  const builder = []
  if (city) builder.push(setCityCase(city))
  if (state) builder.push(state.toUpperCase())
  return builder.join(', ')
}

// Returns initials from first and last name. Example: EXO Freight Company -> EC
export const getInitialsFromName = (name?: string) => {
  let words = (name ?? '').split(' ')
  const firstWord = words[0]
  const lastWord = words.length > 1 ? words.slice(-1)[0] : ''
  words = [firstWord, lastWord]

  return words
    .map((name: string) => name.charAt(0))
    .join('')
    .toUpperCase()
}

export const getOrderingString = (
  label: string,
  direction: string,
  key: string,
  defaultKey = '',
) => (label ? `${direction === 'ascending' ? '' : '-'}${key}` : defaultKey)

export const getStringFromArray = (array = []) =>
  array?.map((item: { id: number }) => item.id).join(',')

// Retracts the location path from window url, e.g. 'https://tms.exofreight.com/loads' => 'loads'.
// The returned value is used as key to retrieve all presets for this location, in the example above all presets for loads
export const getPresetKey = () => window.location.pathname.replace(/[/-]/g, '')

// Generates a random 7-character string used for ensuring file name uniqueness
export const randomString = () => Math.random().toString(36).substring(2, 9)

// Returns true if email string follows an email pattern or if it's blank and not required, otherwise false
export const validateEmail = (email: string, required = false) =>
  (!required && !email.length) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

// Returns true if phone string is 10 digits or if it's blank and not required, otherwise false
export const validatePhoneNumber = (phone: string, required = false) =>
  (!required && !phone.length) || phone.replace(/\D/g, '').length === 10

export const formatTimezone = (timezone: string): string => {
  const map: { [key: string]: string } = {
    'America/New_York': 'ET',
    'America/Chicago': 'CT',
    'America/Denver': 'MT',
    'America/Los_Angeles': 'PT',
    'America/Phoenix': 'MT',
    'America/Tijuana': 'PT',
    'America/Mexico_City': 'CT',
    'America/Monterrey': 'CT',
    'America/Regina': 'CT',
    'America/Winnipeg': 'CT',
    'America/Edmonton': 'MT',
    'America/Vancouver': 'PT',
    'America/Toronto': 'ET',
  }

  return map[timezone] || timezone
}

export const serializeAddress = (address: any) =>
  `${address.street ? `${address.street}, ` : ''}${address.city ? `${address.city}, ` : ''}${
    address.stateCode ? `${address.stateCode} ` : ''
  }${address.postalCode ? `${address.postalCode}, ` : ''}${address.countryCode || ''}`

export const camelCaseToWords = (str: string) =>
  str
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

/*
  Accepts current table order, headers available for ordering, and the label that was clicked
  Returns the display object for a table order.
 */
export const getTableOrderDisplay = (
  headers: Array<{ label: string; key: string }>,
  label: string,
  orderBy?: TableOrder,
) => {
  const isAscending = orderBy?.direction === 'ascending'
  const header = headers.find(header => header.label === label)
  if (!header) return {}

  const key = header.key

  return orderBy?.label === label
    ? {
        label: isAscending ? label : '',
        direction: isAscending ? 'descending' : '',
        key: isAscending ? key : '',
      }
    : { label: label, direction: 'ascending', key }
}

export const getProjectStatusBackground = (status = '') => {
  switch (status.toLowerCase()) {
    case 'active':
      return 'bg-success text-white'
    case 'draft':
      return 'bg-exo-orange text-white'
    case 'completed':
      return 'bg-dark-sky-blue text-white'
  }
}

export type GroupedDocuments = {
  label: string
  files: Array<Partial<Document>>
}

export const normalizeDocuments = <T extends Document>({
  documents,
  displayKey = 'documentTypeDisplay',
  dateKey,
  determineSelectable = () => true,
}: {
  documents: Array<T>
  displayKey?: keyof T
  dateKey?: keyof T
  determineSelectable?: (document: T) => boolean
}): GroupedDocuments[] => {
  const groupedDocuments = groupBy(documents, displayKey)

  const docs = Object.keys(groupedDocuments).map(key => ({
    label: key,
    files: groupedDocuments[key].map(document => ({
      fileName: document.fileName,
      file: document.file,
      date: dateKey ? document[dateKey] : document['timestamp'] || document['timeStamp'],
      id: document.id,
      selectable: determineSelectable ? determineSelectable(document) : true,
      annotations: document.annotations,
    })),
  }))

  docs.sort((a, b) => {
    // sort docs by files containing id of -1 (new files)
    const aHasId = a.files.some(file => file.id === -1)
    const bHasId = b.files.some(file => file.id === -1)
    if (aHasId && !bHasId) return -1
    if (!aHasId && bHasId) return 1

    // sort docs by files containing selectable files
    const aSelectable = a.files.some(file => file.selectable)
    const bSelectable = b.files.some(file => file.selectable)
    if (aSelectable && !bSelectable) return -1
    if (!aSelectable && bSelectable) return 1

    // sort docs by label name
    return a.label.localeCompare(b.label)
  })

  return docs as GroupedDocuments[]
}
