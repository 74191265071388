import { broadcastLogout } from '@common'
import { AppLogo, Button } from '@components'
import { Menu } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import explore from '../../assets/images/explore.png'
import { isProduction } from '../../common/constants'
import { classNames } from '../../common/utils'
import { setCurrentStep, setTutorialWizardVisible } from '../../redux/userSlice'
import { Avatar } from '../Avatar/Avatar'
import { EaseInOutTransition } from '../EaseInOutTransition'
import { links } from '../Sidebar'
import { WalkthroughWizard } from '../WalkthroughWizard'
import { CustomerCompanyPicker } from './CustomerCompanyPicker'

export const Header = (): JSX.Element => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const location = useLocation()

  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const user = useAppSelector(state => state.user.user)
  const completedSteps = useAppSelector(state => state.user.userCompletedSteps)

  const isQuoting = location.pathname.includes('/quotes')

  useEffect(() => {
    if (user?.isFirstLogin) dispatch(setTutorialWizardVisible(true))
  }, [user?.isFirstLogin])

  const renderDropdown = () => (
    <EaseInOutTransition>
      <div className='absolute right-[235px]'>
        <Dropdown>
          <div className='flex flex-col p-4 pb-2 gap-y-2'>
            <Menu.Item disabled>
              <p className='text-right text-sm uppercase font-bold font-sans text-brand-dark'>
                {user?.firstName} {user?.lastName}
              </p>
            </Menu.Item>
            <Menu.Item disabled>
              <div className='flex justify-between'>
                <MenuLabel>Company</MenuLabel>
                <MenuData>{user?.companyName}</MenuData>
              </div>
            </Menu.Item>
            <Menu.Item disabled>
              <div className='flex justify-between'>
                <MenuLabel>Email</MenuLabel>
                <MenuData>{user?.email}</MenuData>
              </div>
            </Menu.Item>
            {user?.phone && (
              <Menu.Item disabled>
                <div className='flex justify-between'>
                  <MenuLabel>Phone</MenuLabel>
                  <MenuData>{user?.phone}</MenuData>
                </div>
              </Menu.Item>
            )}
            <Divider />
            <Menu.Item>
              <MenuButton type='submit' onClick={() => navigate('/account')}>
                My Account
              </MenuButton>
            </Menu.Item>
            <Divider />
            <Menu.Item>
              <MenuButton type='submit' onClick={broadcastLogout}>
                Sign Out
              </MenuButton>
            </Menu.Item>
          </div>
        </Dropdown>
      </div>
    </EaseInOutTransition>
  )

  const linkClassName = 'stroke-black font-bold text-base transition-all flex items-center'

  const renderLinks = () => (
    <>
      {links.map((link, i) => (
        <NavLink
          key={i}
          to={link.url}
          className={({ isActive }) =>
            classNames(linkClassName, isActive ? 'text-brand-accent' : '')
          }
          onClick={() => setMenuOpen(false)}
        >
          <div className='w-7 mr-4'>{link.icon}</div>
          <div className='py-3'>{link.label}</div>
        </NavLink>
      ))}
    </>
  )

  const name = `${user?.firstName} ${user?.lastName}`

  const renderBurger = () => (
    <HamburgerMenu $isOpen={isMenuOpen} className='burger-wrap'>
      <LinkWrap onClick={() => setMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? (
          <XMarkIcon className='text-brand-primary w-6 h-10 m-auto' />
        ) : (
          <Bars3Icon className='text-white w-6 h-10 m-auto' />
        )}
      </LinkWrap>
    </HamburgerMenu>
  )

  return (
    <HeaderContainer $isOpen={isMenuOpen}>
      <div className='flex items-center'>
        <NavLink to={isQuoting ? '/' : links[1].url}>
          <AppLogo />
        </NavLink>
      </div>
      {!isProduction && (
        <div className='hidden lg:block bg-brand-accent text-brand-dark text-sm font-bold uppercase px-2 py-1 rounded'>
          {(import.meta as any).env.VITE_ENVIRONMENT}
        </div>
      )}

      <div className='flex items-center gap-4 lg:gap-8'>
        <Button
          lg
          className='text-xs'
          type='light-blue'
          onClick={() => {
            dispatch(setCurrentStep(completedSteps.length ? 'START' : ''))
            dispatch(setTutorialWizardVisible(true))
          }}
        >
          <div className='flex items-center gap-4'>
            Explore Pulse
            <img alt='explore' className='w-5' src={explore} />
          </div>
        </Button>
        <CustomerCompanyPicker />
        <MenuContainer as='div'>
          <div className='flex items-center'>
            <AvatarWrap>
              <AvatarContainer dropdown name={name} />
            </AvatarWrap>
            <BurgerWrap>{renderBurger()}</BurgerWrap>
          </div>
          <MobileMenu $isOpen={isMenuOpen}>
            {isMenuOpen && (
              <>
                {renderLinks()}
                <MobileFooter>
                  <NavLink to='/account' onClick={() => setMenuOpen(false)}>
                    <Button type='secondary'>My Account</Button>
                  </NavLink>
                  <Button preIcon='logout' type='danger' onClick={broadcastLogout}>
                    Sign Out
                  </Button>
                </MobileFooter>
              </>
            )}
          </MobileMenu>
          {renderDropdown()}
        </MenuContainer>
      </div>
      <WalkthroughWizard />
    </HeaderContainer>
  )
}

const MenuButton = tw.button<{ $selected?: boolean }>`
  text-xs
  text-center
  font-semibold
  ${({ $selected }) => $selected && 'bg-lighter-blue font-semibold'}
`

const MobileMenu = tw.div<{ $isOpen: boolean }>`
  fixed
  top-0
  right-0
  w-full
  pl-6
  pt-2
  bg-white
  text-center
  opacity-0
  text-3xl
  mt-[72px]
  md:hidden
  overflow-auto
  pb-2
  ${({ $isOpen }) => $isOpen && 'opacity-100 transition-opacity delay-75 h-full'}
`

const HamburgerMenu = tw.div<{ $isOpen: boolean }>`
  bg-brand-dark
  rounded-lg
  ml-4
  m-0
  p-0
  block
  md:hidden
  h-10
  w-10
  ${({ $isOpen }) => $isOpen && 'bg-white'}
`

const HeaderContainer = tw.div<{ $isOpen: boolean }>`
  fixed
  top-0
  w-full
  bg-brand-primary
  flex
  justify-between
  items-center
  py-4
  px-6
  gap-3
  h-[72px]
  md:relative
  ${({ $isOpen }) => $isOpen && 'z-[100]'}
`

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
  z-10
`

const AvatarWrap = tw(Menu.Button)`
  hidden
  lg:block
  mr-0
  w-10
  h-10
  transition-colors
`

const AvatarContainer = tw(Avatar)`
  text-white
  text-lg
  bg-brand-dark
  hover:bg-gray-600
`

const MobileFooter = tw.div`
  fixed
  flex
  justify-between
  bg-brand-primary
  w-full
  bottom-0
  left-0
  flex
  items-center
  text-left
  text-white
  font-bold
  text-sm
  p-4
`

const BurgerWrap = tw.div`
  md:hidden
  top-0
  -right-4
  transition-all
`

const LinkWrap = tw.a`
  text-brand-accent
  absolute
  cursor-pointer
  m-0
  p-0
  h-10
  w-10
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  fixed
  -right-[240px]
  mt-2
  rounded-md
  shadow-lg
  bg-white
  ring-1
  ring-black
  ring-opacity-5
  bottom-[88px]
  focus:outline-none
  md:absolute
  md:left-auto
  md:bottom-auto
  !min-w-max
  !z-[9999]
  w-[180px]
`

const MenuLabel = tw.span`
  text-xs
  text-dark-gray
  pr-4
`

const MenuData = tw.span`
  text-xs
  text-black
`

const Divider = tw.hr`
  mx-[-1rem]
`
