import {
  ArchiveBoxIcon,
  ChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  FolderIcon,
  GlobeAltIcon,
  InboxStackIcon,
  PauseIcon,
  TruckIcon,
} from '@heroicons/react/24/outline'

import { SidebarLink } from '../../common/types'
import { ShipmentIcon } from '../Icons'

type DISPLAY_CONDITIONALS = {
  shipperPriceEstimator: boolean // SHIPPER_PRICE_ESTIMATOR feature flag
  portalQuotingEnabled: boolean // portalQuotingEnabled user property
  projectLoad: boolean
  rfp: boolean
}

export const links: Array<
  SidebarLink & {
    displayIf?: (keyof DISPLAY_CONDITIONALS)[]
  }
> = [
  {
    icon: <CurrencyDollarIcon className='w-7' />,
    url: '/price-estimator',
    label: 'Price Estimator',
    displayIf: ['shipperPriceEstimator'],
  },
  {
    icon: <ChatBubbleLeftEllipsisIcon className='w-7' />,
    url: '/quotes',
    label: 'Quotes',
    displayIf: ['portalQuotingEnabled'],
  },
  {
    icon: <ShipmentIcon size='20' />,
    url: '/loads',
    label: 'My Loads',
  },
  {
    icon: <InboxStackIcon className='w-7' />,
    url: '/projects',
    label: 'My Projects',
    displayIf: ['projectLoad'],
  },
  {
    icon: <FolderIcon className='w-7' />,
    url: '/rfps',
    label: 'My RFPs',
    displayIf: ['rfp'],
  },
  {
    icon: <PauseIcon />,
    url: '/contract-lanes',
    label: 'Contract Lanes',
  },
  {
    icon: <GlobeAltIcon className='w-7' />,
    url: '/facilities',
    label: 'Facilities',
  },
  {
    icon: <TruckIcon className='w-7' />,
    url: '/carriers',
    label: 'Carriers',
  },
  {
    icon: <ArchiveBoxIcon />,
    url: '/items',
    label: 'Items',
  },
  {
    icon: <ChartBarIcon />,
    url: '/my-stats',
    label: 'Reporting',
  },
  {
    icon: <DocumentIcon />,
    url: '/invoices',
    label: 'Invoices',
  },
]
