import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import { getIcon } from '../../common/utils'

export const DetailIconItem = ({
  icon,
  children,
  className = '',
}: {
  icon: string
  children: ReactNode
  className?: string
}) => (
  <Container className={className}>
    {getIcon(icon, 'text-brand-dark w-4 h-4 mr-3')}
    <Value>{children || '—'}</Value>
  </Container>
)

export const DetailIconLabelItem = ({
  icon,
  items = [],
  className = '',
  label,
  children,
  bold,
}: {
  icon?: string
  items?: Array<{ label: string; value: string | number | null }>
  label?: string
  children?: ReactNode
  className?: string
  bold?: boolean
}) => (
  <Container className={className}>
    {icon && getIcon(icon, 'text-brand-dark w-4 h-4 mr-3')}
    {items.length ? (
      items.map(item => (
        <div key={item.label} className='flex'>
          <Label>{item.label}</Label>
          <Value $bold={bold}>{item.value || '—'}</Value>
        </div>
      ))
    ) : (
      <>
        <Label>{label}</Label>
        <Value $bold={bold}>{children || '—'}</Value>
      </>
    )}
  </Container>
)

export const DetailItem = ({
  label,
  children,
  bold,
  className = '',
  extraText,
  labelClassName,
}: {
  label: string
  children: ReactNode
  bold?: boolean
  className?: string
  extraText?: string
  labelClassName?: string
}) => (
  <Container className={className}>
    <Label className={labelClassName}>{label}</Label>
    <Value $bold={bold}>
      {children || '—'} {extraText && extraText}
    </Value>
  </Container>
)

export const HalfWidthDetailItem = ({
  label,
  children,
  className = '',
}: {
  label?: string
  children: ReactNode
  className?: string
}) => (
  <div className={className}>
    {label && <Label className='mb-1'>{label}</Label>}
    <Value>{children || '—'}</Value>
  </div>
)

const Container = tw.div`
  flex
  my-1.5
  justify-between
`

const Value = tw.div<{ $bold?: boolean }>`
  mr-3
  text-brand-dark
  text-xs
  ${({ $bold }) => $bold && 'font-semibold'}
`

const Label = tw.div`
  text-brand-dark
  text-xs
  mr-3
  font-semibold
`
