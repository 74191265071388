import { Button, Form, TextInput } from '@components'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import logoBlue from '../../assets/images/login/logo-blue.png'
import { getDocumentTitle, getPhoneMaskOptions } from '../../common/utils'
import { Error, Help, Link, LoginPageContainer } from '../../components/Login/components'
import {
  registerShipper,
  resetLogin,
  setCredentials,
  setRegister,
  setStep,
} from '../../redux/userSlice'

const phoneMask = getPhoneMaskOptions()

export const RegisterScreen = (): JSX.Element => {
  const dispatch = useAppThunkDispatch()
  const navigate = useNavigate()
  const [isFormValid, setFormValid] = useState(false)
  const register = useAppSelector(state => state.user.register)
  const isRegistering = useAppSelector(state => state.user.loading.registerShipper)
  const credentials = useAppSelector(state => state.user.credentials)
  const error = useAppSelector(state => state.user.error)

  const onRegister = useCallback(async () => {
    dispatch(setCredentials({ ...credentials, username: '' })) // reset login email so there are no clashes
    const response = await dispatch(registerShipper())
    if (response.meta.requestStatus === 'rejected') return

    dispatch(setStep('CONFIRM'))
    navigate('/')
  }, [])

  useEffect(() => {
    document.title = getDocumentTitle('Register')
    dispatch(resetLogin())
  }, [])

  return (
    <LoginPageContainer>
      <FormContainer>
        <div className='flex flex-col items-center mb-1 w-full'>
          <img alt='logo' className='w-[80px]' src={logoBlue} />
          <div className='text-2xl font-semibold mt-6'>Create Account</div>
          <div className='w-full text-sm text-white text-center font-bold mt-4 py-4 px-2 bg-shiny-blue rounded-lg'>
            <p>Start your 14-day free trial today</p>
            <p className='mt-1'>No credit card needed</p>
          </div>
        </div>
        <Form
          autoComplete='off'
          className='flex flex-col gap-4 items-center w-full'
          setFormValid={setFormValid}
        >
          <TextInput
            required
            sm
            className='w-full'
            label='Company Name'
            value={register.companyName}
            onChange={companyName => dispatch(setRegister({ companyName }))}
          />
          <div className='flex gap-2 w-full'>
            <TextInput
              required
              sm
              className='w-full'
              label='First Name'
              value={register.firstName}
              onChange={firstName => dispatch(setRegister({ firstName }))}
            />
            <TextInput
              required
              sm
              className='w-full'
              label='Last Name'
              value={register.lastName}
              onChange={lastName => dispatch(setRegister({ lastName }))}
            />
          </div>
          <TextInput
            required
            sm
            className='w-full'
            label='Work Email'
            type='email'
            value={register.email}
            onChange={email => dispatch(setRegister({ email }))}
          />
          <TextInput
            required
            sm
            className='w-full'
            label='Phone Number'
            maskOptions={phoneMask}
            value={register.phone}
            onChange={phone => dispatch(setRegister({ phone }))}
          />
          <TextInput
            sm
            className='w-full'
            label='What is your role in the company?'
            value={register.roleInCompany}
            onChange={roleInCompany => dispatch(setRegister({ roleInCompany }))}
          />
          {error && <Error className='mb-2 text-sm'>{error}</Error>}
          <Button
            fullWidth
            lg
            className='w-full'
            disabled={!isFormValid}
            loading={isRegistering}
            type='warn'
            onClick={onRegister}
          >
            Create Account
          </Button>
        </Form>
        <div className='text-center text-sm'>
          <div className='mb-4'>
            Have an account?{' '}
            <Link
              onClick={() => {
                navigate('/')
                window.location.reload() // force reload, otherwise the it get's stuck with the loading gif
              }}
            >
              Log in
            </Link>
          </div>
          <Help />
        </div>
      </FormContainer>
    </LoginPageContainer>
  )
}

const FormContainer = tw.div`
  flex
  w-full
  md:w-1/2
  lg:w-5/12
  flex-col
  justify-center
  mt-10
  md:mt-0
  items-center
  gap-6
  w-full 
  max-lg:px-12 
  lg:px-20 
  xl:px-24
`
